<template>
<div class="banner">
<div class="container">
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <h4>
        Единственным способом устранения катаракты является микрохирургическая операция
      </h4>
      <p>
        Операция по удалению катаракты в Центре глазной хирургии проводится бесплатно и без очереди по полису ОМС.
      </p>
    </div>
    <div class="col-md-4 col-xs-12">
      <img src="@/assets/images/banner-img.png" alt="">
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: "PageBanner"
}
</script>

<style scoped>
.banner{
  background: #09A3F2;
  color: #fff;
}
h4{
  font-weight: bold;
}

.banner p{
  padding-bottom: 0;
  margin-bottom: 0;
}
.banner .row>div{
padding: 90px 90px 90px 0;
}
.banner img{
  position: absolute;
  top:calc(-25%);
}
.banner .col-md-4{
  position: relative;
}

@media screen and (max-width: 768px) {
  .banner{
    text-align: center;
  }
  .banner .row>div{
    padding: 50px 0 0 0 ;
  }
  .banner img{
    display: none;
  }
}
</style>