<template>
  <div class="container">
    <div class="row">
      <h2 class="text-center">Как получить услугу по полису ОМС</h2>
    </div>

    <div class="row desktop-tabs">
      <div class="col-md-12 tabs">
        <div class="tab btn" v-bind:class="{active: oms.tab1}" @click="switchOmsTabs('tab1')">
          1. Запись на приём
        </div>
        <div class="tab btn" v-bind:class="{active: oms.tab2}" @click="switchOmsTabs('tab2')">
          2. Обследование
        </div>
        <div class="tab btn" v-bind:class="{active: oms.tab3}" @click="switchOmsTabs('tab3')">
          3. Подготовка
        </div>
        <div class="tab btn" v-bind:class="{active: oms.tab4}" @click="switchOmsTabs('tab4')">
          4. Операция
        </div>
      </div>
    </div>



    <div class="oms-tab-window oms-desktop" v-if="oms.tab1">
      <div class="row">
        <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-1.png" alt=""></div>
        <div class="col-md-8 col-xs-12">
          <h4>
            Запись на приём
          </h4>
          <p>
            Связываетесь с нами удобным для Вас способом:
            <br> -позвонить <a href="tel:+79370090303">+7 (937) 009-03-03</a>
            <br> -написать в Whatsapp по номеру <a href="https://wa.me/79370090303">+7 (937) 009-03-03</a>
            <br> -оставить заявку на сайте, и мы Вам перезвоним.
          </p>
        </div>
      </div>
    </div>

    <div class="oms-tab-window oms-desktop" v-if="oms.tab2">
      <div class="row">
        <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-2.png" alt=""></div>
        <div class="col-md-8 col-xs-12">
          <h4>Офтальмологическое обследование</h4>
          <p>
            Перед операцией необходимо пройти обследование у врача-офтальмолога.
          </p>
          <p>
            Обследование в среднем занимает до 1,5-2 часов, для расширения зрачка Вам закапывают специальные капли, после чего зрение будет слегка затуманено в течение 4-6 часов.
          </p>

        </div>
      </div>
    </div>

    <div class="oms-tab-window oms-desktop" v-if="oms.tab3">
      <div class="row">
        <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-3.png" alt=""></div>
        <div class="col-md-8 col-xs-12">
          <h4>
            Подготовка
          </h4>
          <p>
            <strong>Необходимо получить направление по форме №057/у-04, выданное по месту жительства у одного из специалистов: </strong>
            офтальмолога, терапевта, хирурга, ВОП.
            <br>
           Скачать бланк направления:
            <a download href="/files/zayavlenie_Shemordan.docx">Шемордан, </a>
            <a download href="/files/zayavlenie_Khuzangaeva.docx">Хузангаево, </a>
            <a download href="/files/zayavlenie_Elabuga.docx">Елабуга</a>
          </p>
          <p>
            Что делать, если  Вам отказывают в выдаче направления?
            <br>
            <a download href="/files/pri_otkaze_napravleniya.docx"> Скачать инструкцию</a>
          </p>
          <p>
            Сдать  анализы согласно списку
            <br>
            <a download href="/files/Spisok_analizov.docx"> Скачать список</a>
          </p>

          <p>
            Для лечения по полису ОМС необходимы  документы:
            <ul>
              <li>паспорт</li>
              <li>полис ОМС</li>
              <li>СНИЛС</li>
            </ul>
          </p>
        </div>
      </div>
    </div>

    <div class="oms-tab-window oms-desktop" v-if="oms.tab4">
      <div class="row">
        <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-4.png" alt=""></div>
        <div class="col-md-8 col-xs-12">
          <h4>
            Операция
          </h4>
          <p>
            Лечение катаракты проводится при помощи методики ультразвуковой факоэмульсификации.
            Операция проводится в режиме «одного дня» под капельной анестезией, длится около 15 минут.
            С учетом подготовки к операции и самой операции Вы проведете в клинике около 1,5-2 ч.
            Улучшение зрения ощущается уже во время операции, а максимального уровня оно достигает через несколько дней.
          </p>
          <p>
            На следующий день после операции необходим осмотр в клинике. График дальнейших осмотров – по рекомендации врача.
          </p>
          <p>
            После операции не рекомендуется посещение бани, сауны, открытых водоемов в течение 4-х недель.
            Также рекомендуется в течение 10 дней на улице надевать затемненные очки для защиты от яркого света.
          </p>
        </div>
      </div>
    </div>

    <!--Слайдер для мобильки-->
    <div class="owl-carousel owl-theme" id="owl-oms">

      <div class="oms-tab-window">
        <div class="row">
          <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-1.png" alt=""></div>
          <div class="col-md-8 col-xs-12">
            <h4>
              Запись на приём
            </h4>
            <p>
              Связываетесь с нами удобным для Вас способом:
              <br> -позвонить <a href="tel:+79372900303"> +7 937 290 03 03</a>
              <br> -написать в Whatsapp по номеру <a href="https://wa.me/79372900303">+7 937 290 03 03</a>
              <br> -оставить заявку на сайте, и мы Вам перезвоним.
            </p>
          </div>
        </div>
      </div>


      <div class="oms-tab-window" >
        <div class="row">
          <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-2.png" alt=""></div>
          <div class="col-md-8 col-xs-12">
            <h4>Офтальмологическое обследование</h4>
            <p>
              Перед операцией необходимо пройти обследование у врача-офтальмолога.
            </p>
            <p>
              Обследование в среднем занимает до 1,5-2 часов, для расширения зрачка Вам закапывают специальные капли, после чего зрение будет слегка затуманено в течение 4-6 часов.
            </p>

          </div>
        </div>
      </div>

      <div class="oms-tab-window">
        <div class="row">
          <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-3.png" alt=""></div>
          <div class="col-md-8 col-xs-12">
            <h4>
              Подготовка
            </h4>
            <p>
              <strong>Необходимо получить направление по форме №057/у-04, выданное по месту жительства у одного из специалистов: </strong>
              офтальмолога, терапевта, хирурга, ВОП.
              <br>
              Скачать бланк направления:
              <a download href="/files/zayavlenie_Shemordan.docx">Шемордан, </a>
              <a download href="/files/zayavlenie_Khuzangaeva.docx">Хузангаево, </a>
              <a download href="/files/zayavlenie_Elabuga.docx">Елабуга</a>
            </p>
            <p>
              Что делать, если  Вам отказывают в выдаче направления?
              <br>
              <a download href="/files/pri_otkaze_napravleniya.docx"> Скачать инструкцию</a>
            </p>
            <p>
              Сдать  анализы согласно списку
              <br>
              <a download href="/files/Spisok_analizov.docx"> Скачать список</a>
            </p>

            <p>
              Для лечения по полису ОМС необходимы  документы:
            <ul>
              <li>паспорт</li>
              <li>полис ОМС</li>
              <li>СНИЛС</li>
            </ul>
            </p>
          </div>
        </div>
      </div>


      <div class="oms-tab-window" >
        <div class="row">
          <div class="col-md-4 col-xs-12"><img src="@/assets/images/katarakta-slide-4.png" alt=""></div>
          <div class="col-md-8 col-xs-12">
            <h4>
              Операция
            </h4>
            <p>
              Лечение катаракты проводится при помощи методики ультразвуковой факоэмульсификации.
              Операция проводится в режиме «одного дня» под капельной анестезией, длится около 15 минут.
              С учетом подготовки к операции и самой операции Вы проведете в клинике около 1,5-2 ч.
              Улучшение зрения ощущается уже во время операции, а максимального уровня оно достигает через несколько дней.
            </p>
            <p>
              На следующий день после операции необходим осмотр в клинике. График дальнейших осмотров – по рекомендации врача.
            </p>
            <p>
              После операции не рекомендуется посещение бани, сауны, открытых водоемов в течение 4-х недель.
              Также рекомендуется в течение 10 дней на улице надевать затемненные очки для защиты от яркого света.
            </p>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: "OMSBlock",
  data(){
    return{
      oms:{
        tab1:true,
        tab2:false,
        tab3:false,
        tab4:false
      }
    }
  },

  methods:{
    switchOmsTabs(tab){
      if(tab==='tab1'){
        this.oms.tab1=true
        this.oms.tab2=false
        this.oms.tab3=false
        this.oms.tab4=false
      }
      if(tab==='tab2'){
        this.oms.tab1=false
        this.oms.tab2=true
        this.oms.tab3=false
        this.oms.tab4=false
      }
      if(tab==='tab3'){
        this.oms.tab1=false
        this.oms.tab2=false
        this.oms.tab3=true
        this.oms.tab4=false
      }
      if(tab==='tab4'){
        this.oms.tab1=false
        this.oms.tab2=false
        this.oms.tab3=false
        this.oms.tab4=true
      }
    }
  }
}
</script>

<style scoped>
.container{
  padding: 118px 0;
}
h2{
  margin-bottom: 85px;
}
.tabs{
  justify-content: space-evenly;
  display: flex;
}
.tab{
  color: #09A3F2;
  font-weight: bold;
  font-size: 20px;
  border:solid 1px #09A3F2;
}
.tab.active{
  background: #09A3F2;
  color: #fff;
}
.oms-tab-window{
  margin-top: 60px;
}
.oms-tab-window a{
  color: #09A3F2;
}
.desktop-tabs{
  display: block;
}

#owl-oms{
  display: none;
}

@media screen and (max-width: 768px) {
  .oms-desktop{
    display: none;
  }
  #owl-oms{
    display: block;
  }
  .tabs{
    flex-direction: column;
    align-items: center;
  }
  .tab{
    margin-bottom: 15px;
    width: 80%;
  }

  .oms-tab-window{
    padding: 30px;
  }
  h4{
    margin-top: 20px;
  }
  .desktop-tabs{
    display: none;
  }
  h2{
    margin-bottom: 0;
  }
  .oms-tab-window{
    padding-top: 0;
    margin-top: 0;
  }
  .container{
    padding-bottom: 0px;
    padding-top: 50px;
  }
  .owl-carousel .owl-item img{
    width: unset;
    max-height: 150px;
  }
  .owl-item .col-md-4{
    display: flex;
    justify-content: center;
  }

}

</style>
