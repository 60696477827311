<template>
<footer>
  <div class="container">
    <a class="text-center" href="tel:+79370090303">
      +7 (937) 009-03-03
    </a>
    <br>
    <a target="_blank" href="https://shemordan.mcgh.ru/politika-obrabotki-personalnyx-dannyx-v-ooo-mezhrajonnyj-centr-glaznoj-xirurgii/ " class="policy">Политика конфиденциальности</a>
  </div>
  <div class="attention text-center">
    ЕСТЬ ПРОТИВОПОКАЗАНИЯ, НЕОБХОДИМА КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА</div>
</footer>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style scoped>
footer{
  background-color: #09b5f3;
  background-image: -webkit-linear-gradient(322deg, #09b5f3 0%, #0341e0 100%);
  background-image: -o-linear-gradient(322deg, #09b5f3 0%, #0341e0 100%);
  background-image: linear-gradient(128deg, #09b5f3 0%, #0341e0 100%);
  color: #fff;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding: 50px 0 100px 0;
  position: relative;
  text-align: center;
}
a{
  font-size: 3rem;
  font-weight: 900;
  color: #fff;
  display: block;
  text-decoration: none;
}
.footer-address{
  font-size: 1.75rem;
  font-weight: 300;
  text-align: center;
}
.attention{
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #09b5f3;
  font-weight: 300;
  font-size: 1.35rem;
  padding: 10px;
}
.policy{
  background: rgba(0,0,0,.7);
  color: #fff;
  font-size: 14px;
  display: inline;
  padding: 5px 10px;
}

@media screen and (max-width: 768px) {
  footer{
    padding: 25px 0 100px 0;
  }
  a{ font-size: 2rem}
  .attention{
    font-size: 1rem;
  }
}
</style>
