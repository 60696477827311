<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="item">
          <h5>Межрайонный центр глазной хирургии – Хузангаево</h5>
          <a class="link-site" href="https://huzangaevo.mcgh.ru/"
            >Сайт клиники</a
          >
          <h6>
            Татарстан, Алькеевский район, п. Сиктерме, ул. Школьная, д.28
          </h6>
          <p>
            ср.-вс.: 9:00-16:00 <br />
            Пн.-Вт.: Выходной
          </p>
          <a
            target="_blank"
            href="https://yandex.ru/maps/?from=mapframe&ll=49.862590%2C54.771869&mode=usermaps&source=mapframe&um=constructor%3A3695d34ae5cf2a4926bb842be14b6d87d19bff6f358eea54f333fc657e159d61&utm_source=mapframe&z=16"
          >
            Карта проезда
          </a>
          <br />
          <!-- <a href="/files/gde_ostonovitsa_Khuzangaevo.docx" target="_blank"
            >Где остановиться?</a
          > -->
          <p>Вы можете остановиться в хостеле, который находится в здании центра. Стоимость размещения 700 руб. с человека в сутки (без питания). Номера  оборудованы холодильником и микроволновкой. Для заказа обратитесь в регистратуру центра.</p>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Afcd354792c7b11b7187a85e4bd188607c471f2607fff9091a22cf62fa6e45373&amp;source=constructor&scroll=false"
          width="100%"
          height="405"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactsBlock",
};
</script>

<style scoped>
.container {
  margin-bottom: 50px;
  margin-top: 50px;
}
.row {
  display: flex;
  gap: 10px; 
}
.col-md-6,
.col-xs-12 {
  flex: 1;
}
.item {
  border: solid 1px #e6e6e6;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  height: 100%;
}
.item h5 {
  color: #09b5f3;
  margin-bottom: 5px;
}
.link-site {
  margin-bottom: 15px;
  display: inline-block;
}
@media(max-width: 756px) {
  .row {
    flex-direction: column;
  }
  .col-md-6 {
    width: 100%;
  }
}
</style>
