<template>
<div class="why">
<div class="container">
  <h2 class="text-center">Почему нам доверяют</h2>
  <div class="row">
    <div class="col-md-3 col-xs-12 why-item">
      <div class="img">
        <img src="@/assets/images/why-1.png" alt="">
      </div>
      <div class="text">
        Команда профессионалов с 30 летним стажем
      </div>
    </div>

    <div class="col-md-3 col-xs-12 why-item">
      <div class="img">
        <img src="@/assets/images/why-2.png" alt="">
      </div>
      <div class="text">
        Опыт работы с 2009 года

      </div>
    </div>


    <div class="col-md-3 col-xs-12 why-item">
      <div class="img">
        <img src="@/assets/images/why-3.png" alt="">
      </div>
      <div class="text">
        Полная диагностика зрения - 15 исследований
      </div>
    </div>

    <div class="col-md-3 col-xs-12 why-item">
      <div class="img">
        <img src="@/assets/images/why-4.png" alt="">
      </div>
      <div class="text">
        Проведено более 50 тысяч операций по катаракте
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: "WhyTrust"
}
</script>

<style scoped>
.why{
  background: #EBF6FB;
  padding:100px 0;
}
h2{
  margin-bottom: 66px;
}
.why-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.text{
  margin-top: 20px;
}

@media screen and (max-width: 768px){
  .text{
    margin-top: 0;
  }

  .why-item{
    margin-bottom: 25px;
  }
  .why{
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
</style>